.project_status_component {
  padding: 0px 0px 0px 10px;
  font-size: 14px;
  overflow: auto;
}

.project_status_heading_text {
  font-weight: bold;
  line-height: 22px;
}

.project_status_heading {
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
}

.project_status_container {
  font-family: 'Adobe Clean', sans-serif;
  border-radius: 4px;
}

.project_status_clear_all_container {
  overflow: hidden;
  padding: 4px 0px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #1976d2;
}

.project_status_clear_all_text {
  font-weight: bold;
  line-height: 20px;
}
