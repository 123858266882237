.container{
    border-bottom: white 2px solid;
    cursor: pointer;
    opacity: 0.5;
}
.container:hover{
    border-bottom: var(--neutral-30) 2px solid;
}
.container_active{
    border-bottom: var(--primary-50) 2px solid;
    opacity: 1;
}
.appIcon {
    position: relative;
    width: 24px;
    height: 24px;
}