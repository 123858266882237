.offlineAlert{
    width: 90vw;
    border: solid 2px #F49E9E;
    border-radius: 5px;
    background-color: #FEE8E6;
    -moz-animation: hideAnimation 0s ease-in 5s forwards;
    /* Firefox */
    -webkit-animation: hideAnimation 0s ease-in 5s forwards;
    /* Safari and Chrome */
    -o-animation: hideAnimation 0s ease-in 5s forwards;
    /* Opera */
    animation: hideAnimation 0s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes hideAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
        border: none;
    }
}
@-webkit-keyframes hideAnimation {
    to {
        width:0;
        height:0;
        border: none;
        visibility:hidden;
    }
}