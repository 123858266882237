.itemHolder{
    width: 85px;
    height: 74px;
    border-radius: 8px;
    background-color: white;
    border: white 1px solid;
}

.itemHolderActive{
    width: 85px;
    height: 74px;
    border-radius: 8px;
    background-color: white;
    border: var(--primary-50) 1px solid;
}

.title{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}