.inPlaceSearchContainer{
    position: relative;
    z-index: 1000;
}

.searchField{
}

.inPlaceSearchResultsContainer {
    margin-top: 10px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    overflow-x: hidden;
    height: 540px;
}
