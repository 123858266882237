.add_contact_parent {
  width: 100%;
  position: relative;
  height: 325px;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #36474f;
  top: 20px;
  font-family: 'Adobe Clean', sans-serif !important;
}

@media screen and (max-width: 768px) {
  .add_contact_parent {
    height: 620px;
  }
}

.add_client_contact_heading {
  position: absolute;
  top: 24px;
  left: 32px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  width: 253px;
}

@media screen and (max-width: 768px) {
  .add_client_contact_heading {
    top: 40px;
    position: static;
  }
}

.new_contact_heading {
  position: absolute;
  top: 24px;
  left: 40px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  width: 253px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.form_parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 12px;
  color: #455a64;
  font-family: 'Adobe Clean', sans-serif;
}

.form_group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .form_group {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }
}

.form_fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.form_input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 250px;
}
@media screen and (max-width: 768px) {
  .form_input {
    width: 100%;
  }
}

.form_label_container {
  align-self: stretch;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.form_label {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.form_label_text {
  position: relative;
  line-height: 16px;
}

.form_input_large {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.new_contact_cancel_text {
  color: #bc1306;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.new_contact_cancel_text:hover {
  color: #bc1306;
}

.new_contact_add_text {
  color: #1976d2;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.new_contact_add_text:hover {
  color: #1976d2;
  cursor: pointer;
}

.back_text {
  color: #1976d2;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.back_text:hover {
  color: #1976d2;
  cursor: pointer;
}

.new_contact_cancel {
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}

.new_contact_add {
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
}

.button_container_new_contact {
  position: absolute;
  right: 54px;
  bottom: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

@media (max-width: 768px) {
  .button_container_new_contact {
    right: 25px;
    gap: 8px;
    bottom: 22px;
  }
}

.icon_add {
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}

.button_container_new_contact_second {
  position: absolute;
  top: 18px;
  left: 40px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: #1976d2;
}

@media (max-width: 768px) {
  .button_container_new_contact_second {
    bottom: 30px;
  }
}

.footer {
  position: absolute;
  bottom: -1px;
  left: 0;
  border-top: 1px solid #dee4e7;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  overflow: hidden;
  text-align: center;
  color: #bc1306;
  background-color: #fafafa;
}

@media (max-width: 768px) {
  .footer {
    left: 0;
    width: 100%;
  }
}
