.header-child {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #eceff1;
    width: 100%;
    height: 2px;
}
.span {
    color: #455a64;
}
.service-date-11-container {
    position: absolute;
    top: 68px;
    right: 18px;
    line-height: 16px;
    text-align: right;
    display: none;
}
.header-item {
    position: absolute;
    width: calc(100% - 36px);
    top: 0px;
    right: 18px;
    left: 18px;
    max-width: 100%;
    overflow: hidden;
    height: 4px;
    object-fit: contain;
}
.icon-contacts {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.contact_icon_orange {
    width: 20px;
    height: 20px;
    padding: 0.833px 2.083px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .contact_icon_orange path{
    stroke: #ff9800;
  }
  .contact_icon_orange rect{
    stroke: #ff9800;
  }

  .contact_icon_grey {
    width: 20px;
    height: 20px;
    padding: 0.833px 2.083px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .contact_icon_grey path{
    stroke: #B0BEC5;
  }
  .contact_icon_grey rect{
    stroke: #B0BEC5;
  }

.adam-novotn {
    position: relative;
    line-height: 26px;
}
.icon-contacts-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.mobile {
    position: relative;
    line-height: 16px;
}
.div {
    width: 120px;
    position: relative;
    font-size: 16px;
    line-height: 20px;
    color: #455a64;
    display: inline-block;
}
.mobile-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    z-index: 0;
}
.phone-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    z-index: 1;
}
.email {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #455a64;
}
.e-mail-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    z-index: 2;
}
.socials-icons {
    width: 104px;
    margin: 0 !important;
    position: absolute;
    top: 0.5px;
    left: 655px;
    height: 24px;
    display: none;
    z-index: 3;
}
.frame-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 31px;
    font-size: 12px;
    color: #728289;
}
.frame-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 22px;
}
.header1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.alena-janik {
    width: 120px;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    color: #455a64;
    display: inline-block;
}
.div3 {
    position: relative;
    font-size: 14px;
    line-height: 18px;
    color: #455a64;
}
.created-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    z-index: 2;
}
.frame-container {
    border-radius: 8px;
    background-color: #f5f7f8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    position: relative;
    gap: 23px;
    font-size: 12px;
    color: #728289;
}
.header-parent {
    position: absolute;
    /* top: 27px; */
    left: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    font-size: 20px;
    color: #36474f;
}
.icon-mass-mail {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}
.info {
    border-radius: 56px;
    background-color: #f2fae9;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
}
.warrning {
    border-radius: 56px;
    background-color: #fee8e6;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
}
.icon-edit {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
}
.iconsstates1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}
.iconsstates {
    position: absolute;
    top: 0px;
    right: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}
.icon_facebook,
.icon_linkedin,
.icon_twitter {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.socials_icons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
}
.socials-icons2 {
    position: absolute;
    top: 60px;
    left: 86.5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}
.tag {
    border-radius: 4px;
    background-color: #eae0fb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
}
.tag1 {
    border-radius: 4px;
    background-color: #fce4ec;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    color: #d81b60;
}
.tag-parent {
    position: absolute;
    top: 0px;
    right: 136px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    color: #6b36ba;
}
.header {
    width: 900px;
    position: relative;
    background-color: #fff;
    height: 203px;
    overflow: hidden;
    flex-shrink: 0;
}
.header-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: #8f9fa6;
}
.address {
    align-self: stretch;
    position: relative;
    line-height: 22px;
}
.bristol {
    margin: 0;
}
.value {
    flex: 1;
    position: relative;
    line-height: 18px;
}
.value-wrapper {
    align-self: stretch;
    border-radius: 8px;
    background-color: #f5f7f8;
    border: 1px solid #f5f7f8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 16px;
    font-size: 14px;
}
.address-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}
.frame-child {
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #8bc34a;
    height: 8px;
}
.active {
    position: relative;
    line-height: 18px;
}
.ellipse-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #455a64;
}
.value-parent {
    align-self: stretch;
    border-radius: 8px;
    background-color: #f5f7f8;
    border: 1px solid #f5f7f8;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 14px;
}
.frame-div {
    position: absolute;
    top: 235px;
    left: 18px;
    width: 770px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}
.employees {
    position: relative;
    line-height: 22px;
}
.icon-filter-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.employees-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.buttons {
    position: absolute;
    top: 11px;
    left: 276px;
    width: 39px;
    height: 18px;
    overflow: hidden;
}
.field {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cfd8dc;
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 8px 8px 12px;
    gap: 8px;
}
.input {
    position: absolute;
    top: calc(50% - 16px);
    left: 14px;
    width: 341px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.buttons-parent {
    position: absolute;
    top: 0px;
    left: 0px;
    border-bottom: 1px solid #cfd8dc;
    box-sizing: border-box;
    width: 369px;
    height: 56px;
}
.buttons1 {
    align-self: stretch;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 4px 0px;
    box-sizing: border-box;
}
.icon-chevron-right {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
}
.context {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 12px;
    color: #728289;
}
.buttons-group {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    text-align: left;
}
.buttons2 {
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    box-sizing: border-box;
}
.context1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: left;
    font-size: 12px;
    color: #728289;
}
.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
}
.context4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    text-align: left;
    font-size: 12px;
    color: #728289;
}
.buttons8 {
    align-self: stretch;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 0px;
    box-sizing: border-box;
}
.buttons10 {
    align-self: stretch;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    box-sizing: border-box;
}
.frame-parent3 {
    position: absolute;
    top: 76px;
    left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 14px;
    text-align: center;
    color: #36474f;
}
.frame-item {
    position: absolute;
    top: 64px;
    right: 8px;
    border-radius: 32px;
    background-color: #dee4e7;
    width: 4px;
    height: 63px;
}
.frame-parent2 {
    align-self: stretch;
    position: relative;
    border-radius: 8px;
    background-color: #f5f7f8;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    height: 439px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: 14px;
    color: #b0bec5;
}
.frame-parent1 {
    position: absolute;
    top: 379px;
    left: 18px;
    width: 369px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 13px;
}
.detail {
    width: 100%;
    position: relative;
    box-shadow: 1px 1px 24px rgba(38, 50, 56, 0.04);
    border-radius: 16px;
    background-color: #fff;
    height: 873px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #36474f;
    font-family: 'Adobe Clean';
}

.employee-container {
    max-width: 400px;
    padding: 16px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .search-input {
    width: 100%;
    margin-bottom: 12px;
  }
  
  .employee-list {
    margin-top: 8px;
  }
  
  .employee-item {
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
  }
  
  .employee-name {
    font-weight: bold;
    color: #333;
  }
  
  .employee-details {
    color: #666;
    font-size: 14px;
  }
  
  .employee-item:last-child {
    border-bottom: none;
  }
  .employee-container svg {
    width: 20px;  /* Adjust icon width */
    height: 20px; /* Adjust icon height */
  }
  .activity_search_input {
    text-align: left;
    color: #8f9fa6;
  }
  .activity_search {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #dee4e7;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .activity_search input[type='text'] {
    border: none !important;
  }
  
  .icon_activity_search {
    width: 30px;
    font-size: 18px;
    color: #cfd8dc;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  

