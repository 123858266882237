.upload_parent {
  width: 635px;
  height: 500px;
  text-align: left;
  font-size: 16px;
  font-family: 'Adobe Clean';
}

.upload_documents_text {
  position: absolute;
  top: 24px;
  left: 32px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  color: #36474f;
  white-space: pre-wrap;
  width: 253px;
}

.upload_desc_text {
  position: absolute;
  top: 56px;
  left: 32px;
  line-height: 20px;
  color: #728289;
}

.upload_inner {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  font-size: 14px;
}

.table_cell_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.table_cell {
  width: 32px;
  /* background-color: #fff; */
  /* border-right: 1px solid #eceff1; */
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 10px 8px; */
}

.table_cell_text {
  flex: 1;
  position: relative;
  line-height: 18px;
}

.table_cell_sub_text_parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}

.table_cell_sub_text {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #728289;
}

.table_column {
  background-color: #dee4e7;
  border-right: 1px solid #cfd8dc;
  box-sizing: border-box;
}
.table_column_counter {
  width: 32px;
  height: 40px;
}
.table_column_file {
  width: 322px;
  padding: 13px 8px;
}

.table_column_file_text {
  font-size: 14px;
  font-weight: bold;
  flex: 1;
  position: relative;
  line-height: 20px;
}

.upload_child {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-top: 1px solid #dee4e7;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  overflow: hidden;
  text-align: center;
  color: #bc1306;
}

.file_upload_container {
  border-radius: 8px;
  background-color: #dfeffd;
  overflow: hidden;
  padding: 8px 5px;
  box-sizing: border-box;
  color: #1976d2;
}

.btn_cancel_container {
  border-radius: 8px;
  background-color: #dfeffd;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #fee8e6;
  background-color: #fee8e6;
}

.btn_upload_container {
  border-radius: 8px;
  background-color: #dfeffd;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
}

.heading_text {
  line-height: 22px;
  font-size: 16px;
  color: #36474f;
  font-weight: bold;
}

.sub_heading_text {
  line-height: 22px;
  font-size: 14px;
  color: #36474f;
  font-weight: bold;
}

.icon_upload_parent {
  margin-left: auto;
  font-size: 14px;
  line-height: 12px;
}

.icon_upload_text {
  padding-left: 8px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: inline-flex;
  color: #1976d2;
}

.btn_cancel {
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  color: #595959;
  background-color: #e5e5e5;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}

.btn_cancel:hover {
  color: #595959 !important;
}

.btn_delete {
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  color: #bc1306;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  border: none;
}
.btn_delete:hover {
  color: #bc1306;
  background-color: #fee8e6;
  text-decoration: underline;
}
.btn_confirm {
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
  white-space: nowrap;
}

/*DELETE POP UP CSS*/
.delete_pop_up_header {
  font-weight: bold;
  position: absolute;
  top: 128px;
  left: calc(50% - 55px);
  font-size: 20px;
  line-height: 26px;
}
.delete_pop_up_line {
  position: absolute;
  top: 178px;
  left: calc(50% - 114px);
  background-color: #dee4e7;
  width: 229px;
  height: 1px;
}
.delete_pop_up_warning_text {
  font-size: 16px;
  position: absolute;
  top: 193px;
  left: calc(50% - 146px);
  line-height: 20px;
  color: #455a64;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 293px;
}
.delete_pop_up_cancel_text {
  position: relative;
  line-height: 22px;
  font-weight: bold;
}
.button_cancel {
  width: 155px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}
.icon_trash {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.delete_pop_up_confirm_text {
  position: relative;
  font-weight: 600;
  color: #bc1306;
  white-space: nowrap;
}
.delete_pop_up_confirm_text:hover {
  color: #bc1306;
}
.button_confirm {
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  box-sizing: border-box;
  /* gap: 8px; */
  text-align: left;
  font-size: 16px;
  color: #bc1306;
}
.buttons_parent {
  position: absolute;
  top: 255px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #1976d2;
}

.ellipse_div {
  width: 20%;
  position: relative;
  border-radius: 50%;
  background-color: #fee8e6;
  height: 72px;
  margin: auto;
  margin-top: 30px;
  position: static;
}
.delete_alert_icon {
  position: relative;
  top: 18px;
  margin: auto;
  width: 72px;
  height: 72px;
}
.delete_pop_up_parent {
  width: 100%;
  position: absolute;
  border-radius: 8px;
  background-color: #fffefe;
  /* border: 1px solid #dee4e7; */
  box-sizing: border-box;
  height: 319px;
  text-align: center;
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean';
}

.trash_icon path {
  stroke: #bc1306;
}

.action_icon {
  cursor: pointer;
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
