.projects {
  position: absolute;
  top: 24px;
  left: 18px;
  line-height: 22px;
}
.frameChild {
  position: relative;
  width: 24px;
  height: 24px;
}
.projectsOverview {
  position: relative;
  line-height: 18px;
  display: inline-block;
  width: 70px;
  flex-shrink: 0;
}
.frameWrapper,
.groupParent {
  display: flex;
  justify-content: flex-start;
}
.groupParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}
.frameWrapper {
  position: absolute;
  top: 62px;
  left: 18px;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--h7-14-size);
}
.projectsParent {
  position: relative;
  background-color: var(--neutral-0);
  border-right: 1px solid var(--neutral-20);
  box-sizing: border-box;
  width: 181px;
  height: 378px;
  overflow: hidden;
  flex-shrink: 0;
}
.reportsOverview {
  position: relative;
  line-height: 18px;
}
.frameGroup {
  position: absolute;
  top: 62px;
  left: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--h7-14-size);
}
.appMenu,
.frameParent {
  position: absolute;
  left: 0;
  z-index: 100000;
}
.frameParent {
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParentInSearch {
  top: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.appMenu {
  top: 56px;
  background-color: var(--neutral-0);
  box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--neutral-30);
  box-sizing: border-box;
  width: 100%;
  height: 378px;
  overflow-y: hidden;
  text-align: left;
  font-size: var(--h6-16-size);
  color: var(--neutral-80);
  font-family: var(--h7-14);
}
.searchContainer{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  padding: var(--gap-5xs);
  padding-left: 22px;
  width: 100%;
  height: 40px;
  background-color: var(--neutral-0);
  border-bottom: 1px solid var(--neutral-20);
  box-sizing: border-box;
}