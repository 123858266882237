.searchResultsContainer {
    margin-top: 10px;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 50vh;
    max-height: 90vh;
}
