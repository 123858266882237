.welcomeText{
    color: var(--neutral-90, #36474F);
    leading-trim: both;
    text-edge: cap;

    font-family: "Adobe Clean";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 120% */
}

.loginContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-145%, -60%);
}

@media (max-width: 1000px) {
    .welcomeText {
        font-size: 24px;
        line-height: 32px;
    }

    .loginContainer {
        left: 50%;
        top: 50px;
        transform: translate(-50%, 0%);
    }
}