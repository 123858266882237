.signature_parent {
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean';
}

.label_heading {
  font-weight: bold;
  line-height: 22px;
}
.label_text {
  font-size: 14px;
  line-height: 18px;
  color: #455a64;
}

.signature_pad_parent {
  border-radius: 8px;
  background-color: #f5f7f8;
  border: 1px solid #eceff1;
  box-sizing: border-box;
  height: 470px;
  overflow: hidden;
  font-size: 12px;
  color: #455a64;
}

.signature_pad_parent_mobile {
  border-radius: 8px;
  background-color: #f5f7f8;
  border: 1px solid #eceff1;
  box-sizing: border-box;
  height: 380px;
  overflow: hidden;
  font-size: 12px;
  color: #455a64;
}

.input_label_text {
  font-size: 12px;
  line-height: 16px;
  color: #455a64;
  text-align: left;
}

.rectangle_parent {
  align-self: stretch;
  height: 300px;
  background-color: var(--neutral-10);
  border: 1.5px solid var(--neutral-20);
  border-radius: 8px;
}

.rectangle_parent_mobile {
  height: 150px;
  width: 97%;
  background-color: var(--neutral-10);
  border: 1.5px solid var(--neutral-20);
  border-radius: 8px;
}

.signature_control {
  width: 100%; /* Adjust based on your layout needs */
  height: 80%; /* Set to desired percentage */
  justify-content: center;
}

.signature_pad {
  width: 100%;
  height: 100%;
  display: block;
}

.signature_pad_mobile {
  width: 100%;
  height: 100%;
  display: block;
  /* border: 1px solid red; */
}

.line_div {
  border-top: 1.5px solid var(--neutral-30);
  box-sizing: border-box;
  height: 1.5px;
  justify-content: center;
}

.label_fullname_text {
  font-size: 24px;
  color: var(--neutral-30);
  font-weight: normal;
  font-family: var(--font-name);
  margin-top: 10px;
}

.label_fullname_text_mobile {
  font-size: 18px;
  color: var(--neutral-30);
  font-weight: normal;
  font-family: var(--font-name);
  margin-top: -5px;
}

.delete_button {
  margin: 0 !important;
  top: 2px;
  right: 0;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  color: #e31607;
}

.icon_trash {
  position: relative;
  flex-shrink: 0;
}

.icon_trash path {
  stroke: var(--danger-60);
}

.lable_delete {
  font-weight: bold;
  font-size: 14px;
  position: relative;
  line-height: 20px;
  min-width: 39px;
  cursor: pointer;
}
.btn_confirm {
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
  white-space: nowrap;
}
@media screen and (max-width: 371px) {
  .input-parent {
    flex-wrap: wrap;
  }
}
