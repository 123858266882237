.container{
    display: flex;
    overflow-y: auto;
    height: calc(100vh - 115px);
    
}
.treeHolder{
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
    width: fill-available;
}

.contextText{
  font-size: 20px;
  font-weight: bold;
  padding-left: 24px;
}

.actionButton{
  color: var(--primary-50);
  font-size: 14px;
  font-weight: bold;
}

.contextHeader{
  padding-right: 10px;
}
.searchHeader{
  padding-right: 10px;
  padding-left: 10px;
}

.filterIcon > svg > path{
  fill: var(--neutral-30);
}

.loading_box {
    width: 60vmin;
    height: 50vmin;
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    
    --border-angle: 0turn;
    --main-bg: conic-gradient(
        from var(--border-angle),
        #213,
        #112 5%,
        #112 60%,
        #213 95%
      );
    
    border: solid 5px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);
    
    background: 
      var(--main-bg) padding-box,
      var(--gradient-border) border-box, 
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
  
    &:hover {
      animation-play-state: paused;
    }
  }
  
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  