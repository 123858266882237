.Title{
    font-size: 18px;
    font-weight: bold;
}

.rowContainer{
    width: 90svw;
    overflow-x: scroll;
}

.fullItemsView{
    width: max-content;
}