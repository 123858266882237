/* For iPhone X, iPhone Xs, iPhone 11 Pro, iPhone 12 Mini, and iPhone 13 Mini: 1080×2340 pixels at 476ppi */
/* @media only screen and (width: 375px) and (height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .header {
    width: 43% !important;
  }
} */

/* For iPhone 12 Pro Max, iPhone 13 Pro Max, and iPhone 14 Plus:1284×2778 pixels at 458ppi */
/* @media only screen and (width: 428px) and (height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .header {
    width: 43% !important;
  }
} */

/* For iPhone 12, iPhone 12 Pro, iPhone 13, iPhone 13 Pro, and iPhone 14: 1170×2532 pixels at 460ppi */
/* @media only screen and (width: 390px) and (height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .header {
    width: 43%;
  }
} */

/* For iPhone 14 Pro Max, iPhone 15 Plus and iPhone 15 Pro Max: 1284×2778 pixels at 460ppi */
/* @media only screen and (width: 430px) and (height: 932px) and (-webkit-device-pixel-ratio: 3) {
  .header {
    width: 43%;
  }
} */

/* For iPhone 14 Pro, iPhone 15 and iPhone 15 Pro: 1179×2556 pixels at 460ppi */
/* @media only screen and (width: 393px) and (height: 852px) and (-webkit-device-pixel-ratio: 3) {
  .header {
    width: 43%;
  }
} */
.header {
  width: 100%;
  min-height: 100px;
  max-height: 300px;
}

.header_main {
  width: inherit;
  position: inherit;
  background-color: #fefffe;
  min-height: 100px;
  max-height: 300px;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  color: #728289;
}

.header_child {
  width: 100%;
  font-size: 20px;
  color: #36474f;
}

.icon_activity {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.activity_name {
  position: relative;
  line-height: 26px;
}

.icon_activity_parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.icon_chevron_right {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.folder_name_parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  color: #728289;
}

.folder_name {
  position: relative;
  line-height: 24px;
}

.sub_folder_name {
  position: relative;
  line-height: 24px;
}

.frame_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.divider {
  width: 2px;
  position: relative;
  background-color: #fff;
  height: 25px;
}

.divider_transparent {
  width: 2px;
  position: relative;
  background-color: transparent;
  height: 25px;
}

.icon-mass-mail {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.ad_allowed {
  height: 36px;
  width: 36px;
  border-radius: 56px;
  background-color: #f2fae9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  box-sizing: border-box;
}

.ad_not_allowed {
  height: 36px;
  width: 36px;
  border-radius: 56px;
  background-color: #fee8e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  box-sizing: border-box;
}
.icon_alert {
  height: 25px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.warning {
  height: 36px;
  width: 36px;
  border-radius: 56px;
  background-color: #fee8e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  box-sizing: border-box;
}
.ref_id {
  position: relative;
  line-height: 18px;
  color: #b0bec5;
  font-size: 14px;
}
.activity_type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.activity_type_text {
  position: relative;
  line-height: 18px;
  font-size: 12px;
}
.tag {
  border-radius: 4px;
  background-color: #eceff1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 8px;
  font-size: 14px;
}
.address_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.address_child {
  position: relative;
  line-height: 16px;
  font-size: 12px;
  color: #728289;
}

.address {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: #455a64;
}
.icon_facebook,
.icon_linkedin,
.icon_twitter {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.socials_icons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
}
.date_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.date_child {
  position: relative;
  line-height: 16px;
  font-size: 12px;
  color: #728289;
}

.date {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: #455a64;
}
.tag_parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  font-size: 12px;
  color: #728289;
}

.field_button_child {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #1976d2;
  height: 8px;
}
.placeholder {
  flex: 1;
  position: relative;
  line-height: 18px;
}

.status_container {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.field_button {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #cfd8dc;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 12px;
  gap: 8px;
}
.select {
  width: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.icon_print {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.icon_list_parent {
  position: relative;
  top: -78%;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  color: #5b6c74;
  float: right;
}
.icon_list_child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

/* .icon_list_child svg:hover {
  fill: #2c89e6;
} */

.atollon_icon:hover path {
  stroke: var(--primary-40);
}

.print_icon:hover path {
  stroke: var(--primary-40);
}

.edit_icon:hover path {
  stroke: var(--primary-40);
}

.tool_icon:hover path {
  stroke: var(--primary-40);
}

.delete_icon:hover path {
  stroke: var(--primary-40);
}

.status {
  font-size: 14px;
  font-weight: 400;
  color: #5b6c74;
}

.statusIndicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5b6c74;
}

.io_text,
.io_label {
  height: 8px;
  position: relative;
  line-height: 16px;
  display: inline-block;
  min-width: 21px;
  font-size: 12px;
  color: #728289;
}
.io_text {
  height: 17px;
  font-size: 14px;
  line-height: 18px;
  color: #455a64;
  min-width: 60px;
}
.io_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.di_text,
.di_label {
  height: 8px;
  position: relative;
  line-height: 16px;
  display: inline-block;
  min-width: 21px;
  font-size: 12px;
  color: #728289;
}
.di_text {
  height: 17px;
  font-size: 14px;
  line-height: 18px;
  color: #455a64;
  min-width: 60px;
}
.di_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.dot_text_container {
  display: flex;
  align-items: center;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-top: 7px;
  margin-left: 5px;
}
