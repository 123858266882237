.add_contact_child {
  position: absolute;
  top: 178px;
  left: calc(50% - 268px);
  background-color: #dee4e7;
  width: 536px;
  height: 1px;
}
.add_client_contact_heading {
  position: absolute;
  top: 24px;
  left: 32px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  width: 253px;
}
.contact_list_heading {
  font-weight: bold;
  position: absolute;
  top: 203px;
  line-height: 22px;
}
.contact_search_field {
  border: none !important;
  border-color: #bc1306 !important;
  flex: 1;
  position: relative;
  line-height: 18px;
}

.icon_contact_search {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.icon_search {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.contact_search_child {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dee4e7;
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.contact_search_child input[type='text'] {
  border: none !important;
}

.contact_search_child_disabled {
  align-self: stretch;
  border-radius: 8px;
  background-color: #e6e6e6;
  border: 1px solid #dee4e7;
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.contact_search_parent {
  position: absolute;
  top: 72px;
  left: 40px;
  width: 87%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
}

@media (max-width: 768px) {
  .contact_search_parent {
    width: 80%;
  }
}

.tab_label {
  position: relative;
  line-height: 18px;
  color: #115292 !important;
}

.tab_chlid {
  border-radius: 4px;
  height: 32px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 11px 7px;
  box-sizing: border-box;
  color: #115292 !important;
  min-width: 80px;
}

.tab_child_selected {
  background-color: #d4eafd;
  border-radius: 4px;
  height: 32px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 11px 7px;
  box-sizing: border-box;
  color: #115292;
  min-width: 80px;
}

.pipe_sign_color {
  color: #b0bec5;
}
.tab_chlid_second {
  border-radius: 4px;
  background-color: #eceff1;
  height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 11px 7px;
  box-sizing: border-box;
  min-width: 80px;
}
.tabs_container {
  position: absolute;
  width: 90%;
  white-space: nowrap;
  top: 130px;
  /* left: 32px; */
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  font-size: 14px;
}
.label4 {
  align-self: stretch;
  position: relative;
  line-height: 16px;
}
.text_content_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 1px;
}
.select_cell {
  width: 212px;
  background-color: #f5f7f8;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 12px 8px 0px;
  box-sizing: border-box;
}
.select_cell1 {
  width: 208px;
  background-color: #f5f7f8;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 12px 8px;
  box-sizing: border-box;
  gap: 10px;
}
.select_cell2 {
  width: 116px;
  background-color: #f5f7f8;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 12px 8px;
  box-sizing: border-box;
  gap: 10px;
}
.select_cell_parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.contacts_search_result {
  position: absolute;
  top: 233px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  font-size: 12px;
  overflow-y: auto;
  height: 321px;
  width: 90%;
}

.contact_search_cancel_text {
  color: #bc1306;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.contact_search_cancel_text:hover {
  color: #bc1306;
}

.contact_search_add_text {
  color: #1976d2;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.contact_search_add_text:hover {
  color: #1976d2;
  cursor: pointer;
}

.new_contact_text {
  color: #1976d2;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.new_contact_text:hover {
  color: #1976d2;
  cursor: pointer;
}

.contact_search_cancel {
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}

.contact_search_add {
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
}

.button_container_contact_search {
  position: absolute;
  right: 54px;
  bottom: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

@media (max-width: 768px) {
  .button_container_contact_search {
    right: 25px;
    gap: 8px;
  }
}

.icon_add {
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.button_container_contact_search_second {
  position: absolute;
  top: 18px;
  left: 40px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* gap: 8px; */
  color: #1976d2;
}

.footer {
  position: absolute;
  bottom: -1px;
  left: 0;
  border-top: 1px solid #dee4e7;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  overflow: hidden;
  text-align: center;
  color: #bc1306;
}

@media (max-width: 768px) {
  .footer {
    left: 0;
    width: 100%;
  }
}

.add_contact_parent {
  width: 100%;
  position: inherit;
  /* border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.94);
  border: 1px solid #dee4e7;
  box-sizing: border-box; */
  height: 520px;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}
