.folderTypeWrapper{
    background-color: var(--neutral-10);
    color: var(--neutral-90);
    padding: 9px 4px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.folderTypeWrapper:hover{
    background-color: var(--neutral-20);
}
.search{
    display: none;
}

.folderTypeWrapper:hover .search{
    display: flex;
}

.selected{
    background-color: var(--primary-0);
}
