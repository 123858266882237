.centerText{
    color: var(--neutral-90, #36474F);
    text-align: center;
    leading-trim: both;    
    text-edge: cap;
    font-family: "Adobe Clean";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 90%;
    align-content: center;
}

@media (max-width: 768px) {
    .centerText {
        font-size: 32px;
        line-height: 40px; /* 125% */    
    }
}