.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.closebtn {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 60px;
  cursor: pointer;
}

.searchContainer {
  position: absolute;
  top: 20px;
  width: 100%;
  max-width: 580px;
}
