.container{
    background-color: var(--neutral-10);
    color: var(--neutral-90);
    font-size: 14px;
    font-weight: 700;
}

.search{
    display: none;
    color: var(--neutral-30)
}
.container:hover .search{
    display: flex;
}
