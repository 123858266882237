.itemContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    cursor: pointer;
}
.itemContainer:hover{
    background-color: gray 1px solid;
    border-left: var(--primary-80) 3px solid;
    padding-left: 8px;
}
.itemContainer_active{
    background-color: var(--primary-10);
    border: var(--primary-80) 1px solid;
}
.searchResultImage{
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.searchResultTitle{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #263238;
}

.searchResultSubtitle{
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #728289;
}

.searchResultBreadcrumbs{
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #728289;
}

.rightContainer{
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: flex-end;
    right: 0px;
}
.searchResultIndicator{
    display: flex;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary-50);
}

.searchResultStatus{   
    display: flex;
    position: relative;
    top: 4px;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #728289;
}