.date_header_text {
  position: relative;
  top: calc(50% - 10px);
  left: 14px;
  line-height: 20px;
}

.side_line {
  background-color: #1976d2;
}

.label {
  position: relative;
  line-height: 16px;
}

.path_label {
  position: relative;
  line-height: 16px;
  font-size: 12px;
}

.icon_chevron_right {
  width: 16px;
  height: 16px;
}

.div_icon {
  color: #1976d2;
  line-height: 16px;
}

.icon_avatar {
  border-radius: 50px;
  object-fit: cover;
}

.status_text {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #36474f;
}

.date_header_parent {
  border-radius: 8px;
  background-color: #f5f7f8;
  overflow: hidden;
}

.icon_stack {
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  border: none;
  background-color: transparent;
  color: #b0bec5;
}

.icon_stack:hover {
  color: #1976d2;
}

.button_parent {
  background-color: #fff;
  overflow: hidden;
  padding: 4px 0px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  color: #1976d2;
}

.btn_see_more {
  font-weight: bold;
  overflow: hidden;
  padding: 4px 0px;
  text-align: center;
  color: #1976d2;
}

.icon_detail {
  position: absolute;
  top: 25px;
  right: 0px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border: none;
  color: #b0bec5;
  background-color: transparent;
}

.parent_container {
  border-radius: 4px;
  background-color: #fff;
  text-align: left;
  font-size: 14px;
  color: #728289;
  font-family: 'Adobe Clean', sans-serif;
  overflow-y: auto;
}

.label > a:hover {
  cursor: pointer;
}
