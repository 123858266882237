.mobileGroup{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.mobileGroupItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: var(--gap-xs);
}
.Title{
    font-size: 18px;
    font-weight: bold;
}