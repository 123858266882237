.AppMenuButton{
    padding-top: 5px;
}

.AppMenuButton:hover{
    cursor: pointer;
}

.tabs_area{
    overflow-x: hidden;
    overflow-y: hidden;
}
.scroll{
    cursor: pointer;
}

.searchIcon{
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchIcon:hover{
    background-color: var(--neutral-0);

}