.mobileMenu {
  /* list-style-type: none; */
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: var(--white);
  font-size: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (display-mode: standalone) {
  .mobileMenu {
    padding-bottom: 15px;
  }
}

.mobileAppScreen {
  position: fixed;
  top: 0;
  overscroll-behavior: none;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100svw;
  height: calc(92svh - 29px) ;
  padding-bottom: 10px;
  background-color: #f5f5f5;
  z-index: 99999;
}

@media (display-mode: standalone) {
  .mobileAppScreen {
    padding-bottom: 0px;
  }
}

.menuButton {
  display: flex;
}

.menuButtonActive {
  color: var(--primary-50);
}

.menuButtonIcon {
  /* padding-right: 12px;
    padding-bottom: 3px; */
}
.mainContainer{
  font-family: 'Adobe Clean';
  background-image: url('/public/assets/images/protected-background-web.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .mainContainer{
    background-image: url('/public/assets/images/protected-background-mobile.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}