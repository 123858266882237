.option{
    border-radius: 7px;
    width: 46px;
    height: 46px;
    font-size: 9px;
}
.option path{
    stroke: #B0BEC5;
}

.selectedOption{
    color: var(--primary-50);
    background-color: #D4EAFD;
    border-radius: 7px;
    width: 46px;
    height: 46px;
    font-size: 9px;
}

.selectedOption path{
    stroke: var(--primary-50);
}