.mainTitle{
    position: relative;
    font-size: 12px;
    font-weight: 700;
    color: #728289;
    top: 12px;
    left: 0px;
}
.mainSearchResultContainer{
    position: relative;
    top: 10px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}