.card_list {
    font-size: 16px;
    color: #36474f;
    font-family: 'Adobe Clean', sans-serif;
  }
  
  .card_child_wrapper {
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    overflow: hidden;
    padding: 18px 18px 14px;
    min-height: 125px;
    max-height: 125px;
  }
  
  .heading_text {
    font-weight: bold;
    line-height: 22px;
  }
  
  .heading_date {
    font-size: 12px;
    line-height: 16px;
    color: #728289;
    text-align: right;
  }
  
  .header_path_wrapper {
    font-size: 14px;
    color: #728289;
  }
  
  .header_path_text {
    line-height: 18px;
  }
  
  .header_divider {
    background-color: #eceff1;
  }
  
  .footer_wrapper {
    border-radius: 4px;
    background-color: #eceff1;
    padding: 0px 8px;
    font-size: 12px;
    color: #728289;
  }
  
  .footer_text_1 {
    line-height: 16px;
  }
  
  .pipe_sign {
    background-color: #fff;
  }
  
  .pipe_sign_transparent {
    width: 2px;
    position: relative;
    background-color: transparent;
    height: 20px;
  }
  
  .footer_text_2 {
    position: relative;
    line-height: 16px;
    color: #b0bec5;
  }
  
  .contact_icon_orange {
    width: 20px;
    height: 20px;
    padding: 0.833px 2.083px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .contact_icon_orange path{
    stroke: #ff9800;
  }
  .contact_icon_orange rect{
    stroke: #ff9800;
  }

  .contact_icon_grey {
    width: 20px;
    height: 20px;
    padding: 0.833px 2.083px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .contact_icon_grey path{
    stroke: #B0BEC5;
  }
  .contact_icon_grey rect{
    stroke: #B0BEC5;
  }
  
  .contact_info h3 {
    margin: 0;
    font-size: 14px;
    color: #333; 
  }
  
  .contact_info p {
    color: var(--Neutral-60, #728289);
    margin-top: 15px;
    font-family: "Adobe Clean";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }
  .contact_info span {
    color: var(--neutral-90, #36474F);
    font-family: "Adobe Clean";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }