.heading_text {
  line-height: 22px;
  font-size: 16px;
  color: #36474f;
  font-weight: bold;
}

.icon_add_parent {
  margin-left: auto;
  font-size: 14px;
  line-height: 12px;
}

.button_container_parent {
  position: absolute;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.btn_cancel {
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}

.btn_cancel_text {
  color: #bc1306;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.btn_cancel_text:hover {
  color: #bc1306;
}

.btn_add {
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
}

.btn_add_text {
  color: #1976d2;
  font-weight: bold;
  position: relative;
  line-height: 22px;
}

.btn_add_text:hover {
  color: #1976d2;
  cursor: pointer;
}

._2n04KW_react-spectrum-DatePicker-cell {
  width: 20px;
}
