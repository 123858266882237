.itemTypeWrapper{
    background-color: var(--neutral-0);
    color: var(--neutral-90);
    padding: 9px 26px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.itemTypeWrapper:hover{
    background-color: var(--neutral-20);
}
