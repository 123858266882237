.container{
    position: relative;
    height: 100%;
    width: calc(100vw);
    justify-items: center;
    align-items: center;
    padding: 0 10px;
    overflow: hidden;
}
.container::before{
    content: '';
    display: block;
    position: absolute;
    top: -36px;
    left: 16px;
    right: 16px;
    height: 40px;
    /* background-color: var(--primary-50); */
    background-color: var(--status-color);
    border-radius: 20px;
    z-index: 2;
}