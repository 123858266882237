.heading_text {
  line-height: 22px;
  font-size: 16px;
  color: #36474f;
  font-weight: bold;
}
.icon_add_parent {
  margin-left: auto;
  font-size: 14px;
  line-height: 12px;
}

.icon_add_text {
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: inline-flex;
  color: #1976d2;
}
