@font-face {
  font-family: "Adobe Clean";
  src: url("/public/assets/fonts/AdobeClean-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("/public/assets/fonts/AdobeClean-Bold.ttf");
  font-weight: 700;
}

body {
  font-family: 'Adobe Clean';
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h7-14: "Adobe Clean";

  /* font sizes */
  --h7-14-size: 14px;
  --h6-16-size: 16px;
  --h8-12-size: 12px;

  /* Colors */
  --neutral-0: #F5F7F8;
  --neutral-10: #ECEFF1;
  --neutral-20: #DEE4E7;
  --neutral-30: #CFD8DC;
  --neutral-40: #B0BEC5;
  --neutral-50: #8f9fa6;
  --neutral-60: #728289;
  --neutral-70:#5b6c74;
  --neutral-80: #455a64;
  --neutral-90: #263238;

  --primary-0: #EFF7FE;
  --primary-10: #D4EAFD;
  --primary-20: #90CAF9;
  --primary-30: #6DB0F3;
  --primary-40: #2C89E6;
  --primary-50: #1976D2;
  --primary-60: #176CBF;
  --primary-70: #1561AD;
  --primary-80: #115292;
  --primary-90: #0E4072;

  --success-0: #F1F8E9;
  --success-10: #DCEDC8;  
  --success-20: #C5E1A5;
  --success-30: #AED581;
  --success-40: #9CCC65;
  --success-50: #8BC34A;
  --success-60: #7CB342;
  --success-70: #689F38;
  --success-80: #558B2F;
  --success-90: #33691E;

  --warning-0: #FFF3E0;
  --warning-10: #FFE0B2;
  --warning-20: #FFCC80;
  --warning-30: #FFB74D;
  --warning-40: #FFA726;
  --warning-50: #FF9800;
  --warning-60: #FB8C00;
  --warning-70: #F57C00;
  --warning-80: #EF6C00;
  --warning-90: #E65100;

  --danger-0: #FEE8E6;
  --danger-10: #FDCCC9;
  --danger-20: #FCA7A1;
  --danger-30: #FB877F;
  --danger-40: #F95E52;
  --danger-50: #F72B1C;
  --danger-60: #E31607;
  --danger-70: #D91507;
  --danger-80: #BC1306;
  --danger-90: #A81105;

  --info-0: #EBF8FE;
  --info-10: #C4EBFD;  
  --info-20: #93DAFB;
  --info-30: #5EC8F8;
  --info-40: #40BEF7;
  --info-50: #14AFF5;
  --info-60: #03A1E8;
  --info-70: #038FCF;
  --info-80: #0270A1;
  --info-90: #025E88;

  --general-0: #EFF7FE;
  --general-10: #D4EAFD;
  --general-20: #90CAF9;
  --general-30: #6DB0F3;
  --general-40: #2C89E6;
  --general-50: #1976D2;
  --general-60: #176CBF;
  --general-70: #1561AD;
  --general-80: #115292;
  --general-90: #0E4072;

  --white: #fff;
  --color-gray-100: #898989;
  --color-gray-200: #202020;

  /* Gaps */
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-11xs: 2px;
  --gap-3xs: 10px;
  --gap-9xs: 4px;
  --gap-lg: 18px;

  /* Paddings */
  --padding-smi: 13px;
  --padding-5xs: 8px;
  --padding-3xs: 10px;
  --padding-10xs: 3px;
  --padding-xs: 12px;
  --padding-12xs: 1px;
  --padding-11xs: 2px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-31xl: 50px;
  --br-22xl: 41px;
}
