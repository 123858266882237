.public-background {
  background-image: url('/public/assets/images/public-background-web.svg');
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #EFF7FE;
}

.disabler {
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  cursor: not-allowed;
}
.black-n-white {
  filter: grayscale(100%);
}

@media (max-width: 1000px) {
  .public-background {
    background-image: url('/public/assets/images/public-background-mobile.svg');
  }
}