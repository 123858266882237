.comment_heading_parent {
  font-size: 16px;
  color: #36474f;
}

.commnet_heading_text {
  font-weight: bold;
  line-height: 22px;
}

.comment_box_wrapper {
  border-radius: 8px;
  background-color: #f5f7f8;
  border: 1px solid #f5f7f8;
  padding: 18px 16px;
  font-size: 14px;
}

.comment_box input[type='text'] {
  border: solid 1px var(--neutral-30);
  border-radius: 8px;
}

.comment_box input[type='text']:hover {
  border: solid 1px var(--neutral-30);
  border-radius: 8px;
}

.btn_cancel {
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  color: #595959;
  background-color: #e5e5e5;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}

.btn_cancel:hover {
  color: #595959 !important;
}

.btn_confirm {
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
  white-space: nowrap;
}
