.searchContainer{
    background-color: #f5f5f5;
    padding: 15px;
    height: 82svh;
}
.separator{
    border-bottom: 1px solid var(--neutral-20);
    margin-bottom: 0px;
    margin-top: 15px;
    width: auto;
}