.general {
  position: absolute;
  top: 24px;
  left: 24px;
  line-height: 22px;
}
.frameChild {
  position: relative;
  width: 24px;
  height: 24px;
}
.finder {
  position: relative;
  line-height: 18px;
}
.frameParent,
.groupParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent {
  position: absolute;
  top: 62px;
  left: 24px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: var(--gap-lg); */
  text-align: center;
  font-size: var(--h7-14-size);
}
.generalParent {
  position: relative;
  background-color: var(--neutral-0);
  border-right: 1px solid var(--neutral-20);
  box-sizing: border-box;
  width: 205px;
  height: 378px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--h6-16-size);
  color: var(--neutral-80);
  font-family: var(--h7-14);
}
