.grid_view_icon {
  position: absolute;
  top: 12px;
  right: 24px;
  width: 40px;
  height: 32px;
}

.full_view_icon {
  position: absolute;
  top: 12px;
  right: 70px;
  width: 40px;
  height: 32px;
}
.icon-add {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.add_new_btn_text {
  position: relative;
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
}

.add_new_btn {
  width: 120px;
  border-radius: 8px;
  border: 1px solid #1976d2;
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 17px 5px 10px;
  gap: 2px;
  color: #1976d2;
}

.add_new_btn:hover {
  text-decoration: none;
}

.activity_search_input {
  text-align: left;
  color: #8f9fa6;
}
.frame_divider {
  background-color: #dee4e7;
}

.header_parent {
  background-color: #fff;
  border-bottom: 1px solid #dee4e7;
  border-top: 1px solid #dee4e7;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #1976d2;
  font-family: 'Adobe Clean', sans-serif;
}

.activity_search {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dee4e7;
  box-sizing: border-box;
  overflow: hidden;
}

.activity_search input[type='text'] {
  border: none !important;
}

.icon_activity_search {
  width: 30px;
  font-size: 18px;
  color: #cfd8dc;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

@media (min-width: 650px) and (max-width: 800px) {
  .toggleBtn {
    right: 85px !important;
  }
}

@media (min-width: 850px) and (max-width: 1024px) {
  .toggleBtn {
    right: 50px !important;
  }
}
