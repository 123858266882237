.mainTitle{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-90);
}

.subTitle{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--neutral-90);
}

.openButton{
    background-color: var(--primary-50);
    color: white;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.installButton{
    background-color: var(--primary-10);
    color: var(--primary-100);
    border-radius: 8px;
    padding: 8px 24px;
    cursor: pointer;
}