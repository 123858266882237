.menu_messages_large_parent {
  box-shadow: 1px 1px 24px rgba(38, 50, 56, 0.04);
  border-radius: 16px;
  background-color: #fff;
  text-align: left;
  font-size: 14px;
  color: #728289;
  font-family: 'Adobe Clean', sans-serif;
}

.address {
  position: relative;
  line-height: 16px;
}

.icon_navigate {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  color: #1976d2;
}

.user_detail_divider {
  width: 1px;
  position: relative;
  background-color: #dee4e7;
  height: 12px;
}

.btn_text {
  font-size: 12px;
  color: #1976d2;
  font-weight: 600;
}

.message_list_parent {
  border-radius: 16px;
  background-color: #fff;
  color: #36474f;
}

.btn_previous {
  border-radius: 8px;
  border: 1px solid #1976d2;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px 17px 5px 18px;
}
.btn_next {
  border-radius: 8px;
  border: 1px solid #1976d2;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px 17px 5px 18px;
}

.avatar_desktop_light {
  position: absolute;
  top: 0px;
  left: 2px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  object-fit: cover;
}

.user_img {
  width: 42px;
  position: relative;
  height: 40px;
}

.user_email {
  position: relative;
  line-height: 24px;
  color: #728289;
}

.user_full_name {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: #36474f;
  text-align: left;
}

.email_subject {
  font-size: 16px;
  line-height: 20px;
  color: #36474f;
}

.subject_parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 12px;
  color: #728289;
}

.email_content_header_parent {
  background-color: #fff;
  box-sizing: border-box;
  padding: 18px;
}

.email_content {
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  overflow-x: auto;
}
.message_list_detail_child {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.message_list_detail_parent {
  text-align: left;
  font-size: 18px;
  color: #36474f;
}
.message_list_detail_wrapper {
  border-radius: 16px;
  background-color: #fff;
  text-align: center;
}
.message_list_divider {
  background-color: #dee4e7;
}

.messages_list_header_parent {
  background-color: rgba(255, 255, 255, 0.92);
  text-align: center;
}

.messages_list_header_child {
  position: relative;
  left: calc(100% - px);
  background-color: #dee4e7;
  width: 1px;
  height: 76px;
}

.message_list_detail_header_parent {
  background-color: rgba(255, 255, 255, 0.92);
  text-align: center;
}

.btn_new_email {
  border-radius: 8px;
  border: 1px solid #1976d2;
  color: #1976d2;
  box-sizing: border-box;
  overflow: hidden;
}

.btn_new_email_text {
  color: #1976d2;
  font-size: 14px;
}

.message_list_header_icon {
  width: 20px;
  color: #b0bec5;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}

.icon_trash {
  width: 20px;
  color: #b0bec5;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.action_button {
  border-radius: 50px;
  border: 1px solid #1976d2;
  box-sizing: border-box;
  overflow: hidden;
}
.message_list_detail_header_divider {
  background-color: #dee4e7;
}
.btn_comment {
  border-radius: 8px;
  border: 1px solid #1976d2;
  box-sizing: border-box;
}

.btn_comment_text {
  color: #1976d2;
  font-size: 14px;
}

.message_list_wrapper {
  box-shadow: 1px 1px 24px rgba(38, 50, 56, 0.04);
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  color: #1976d2;
  font-family: 'Adobe Clean', sans-serif;
}
.div_date {
  font-size: 12px;
  line-height: 16px;
  color: #728289;
  text-align: left;
}

/*DELETE POP UP CSS*/
.btn_delete {
  border: none;
}
.delete_pop_up_header {
  font-weight: bold;
  position: absolute;
  top: 128px;
  left: calc(50% - 80px);
  font-size: 20px;
  line-height: 26px;
}
.delete_pop_up_line {
  position: absolute;
  top: 178px;
  left: calc(50% - 114px);
  background-color: #dee4e7;
  width: 229px;
  height: 1px;
}
.delete_pop_up_warning_text {
  font-size: 16px;
  position: absolute;
  top: 193px;
  left: calc(50% - 146px);
  line-height: 20px;
  color: #455a64;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 293px;
}
.delete_pop_up_cancel_text {
  position: relative;
  line-height: 22px;
  font-weight: bold;
}
.button_cancel {
  width: 155px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}

.delete_pop_up_confirm_text {
  position: relative;
  font-weight: 600;
  color: #bc1306;
  white-space: nowrap;
}
.delete_pop_up_confirm_text:hover {
  color: #bc1306;
}
.button_confirm {
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #bc1306;
}
.buttons_parent {
  position: absolute;
  top: 255px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #1976d2;
}

.ellipse_div {
  width: 20%;
  border-radius: 50%;
  background-color: #fee8e6;
  height: 72px;
  margin: auto;
  margin-top: 30px;
  position: static;
}
.delete_alert_icon {
  position: relative;
  top: 18px;
  margin: auto;
  width: 72px;
  height: 72px;
}
.delete_pop_up_parent {
  width: 100%;
  position: absolute;
  border-radius: 8px;
  background-color: #fffefe;
  box-sizing: border-box;
  height: 319px;
  text-align: center;
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.trash_icon path {
  stroke: #bc1306;
}

/*SEARCH POP UP CSS*/
.btn_search {
  border: none;
}
.search_pop_up_header {
  font-weight: bold;
  position: absolute;
  top: 128px;
  width: 100%;
  font-size: 20px;
  line-height: 26px;
}
.search_pop_up_line {
  position: absolute;
  top: 172px;
  background-color: #dee4e7;
  width: 100%;
  height: 1px;
}
.search_pop_up_textbox {
  font-size: 16px;
  position: absolute;
  top: 193px;
  left: calc(50% - 146px);
  line-height: 20px;
  color: #455a64;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 293px;
}
.search_pop_up_textbox input[type='text'] {
  border-color: #dee4e7;
}
.search_pop_up_cancel_text {
  width: 100%;
  color: #bc1306;
  position: relative;
  line-height: 22px;
  font-weight: bold;
}
.search_button_cancel {
  width: 120px;
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #bc1306;
}

.search_button_cancel:hover {
  color: #bc1306;
}

.search_pop_up_confirm_text {
  width: 100%;
  text-align: center;
  position: relative;
  font-weight: 600;
  color: #1976d2;
  white-space: nowrap;
}
.search_pop_up_confirm_text:hover {
  color: #1976d2;
}
.search_button_confirm {
  width: 120px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #1976d2;
}
.search_buttons_parent {
  position: absolute;
  width: 100%;
  top: 255px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: #1976d2;
}

.search_ellipse_div {
  width: 20%;
  border-radius: 50%;
  background-color: #e7edf2;
  height: 72px;
  margin: auto;
  margin-top: 30px;
  position: static;
}
.search_header_icon {
  position: relative;
  top: 18px;
  margin: auto;
  width: 72px;
  height: 72px;
}

.search_pop_up_parent {
  width: 100%;
  position: absolute;
  border-radius: 8px;
  background-color: #fffefe;
  box-sizing: border-box;
  height: 319px;
  text-align: center;
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

/*------------------------------*/

.email_content_header_parent_large {
  background-color: #fff;
  box-sizing: border-box;
  padding-top: 18px;
  padding-bottom: 18px;
}

.message_list_detail_wrapper_large {
  box-shadow: 1px 1px 24px rgba(38, 50, 56, 0.04);
  border-radius: 16px;
  background-color: #fff;
  text-align: left;
  font-size: 14px;
  color: #1976d2;
  font-family: 'Adobe Clean', sans-serif;
}
