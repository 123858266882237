.card_list {
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.card_child_wrapper {
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  overflow: hidden;
  padding: 18px 18px 14px;
}

.heading_text {
  font-weight: bold;
  line-height: 22px;
}

.heading_date {
  font-size: 12px;
  line-height: 16px;
  color: #728289;
  text-align: right;
}

.header_path_wrapper {
  font-size: 14px;
  color: #728289;
}

.header_path_text {
  line-height: 18px;
}

.header_divider {
  background-color: #eceff1;
}

.footer_wrapper {
  border-radius: 4px;
  background-color: #eceff1;
  padding: 0px 8px;
  font-size: 12px;
  color: #728289;
}

.footer_text_1 {
  line-height: 16px;
}

.pipe_sign {
  background-color: #fff;
}

.pipe_sign_transparent {
  width: 2px;
  position: relative;
  background-color: transparent;
  height: 20px;
}

.footer_text_2 {
  position: relative;
  line-height: 16px;
  color: #b0bec5;
}
