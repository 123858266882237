.service_activities {
  background-color: #f5f7f8;
  text-align: left;
  font-size: 14px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
  padding: 15px;
}

.service_status_bar {
  width: 100%;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  padding-top: 5px;
  object-fit: contain;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 10px;
  /* margin-top: 25px; */
}

.detailsHolder {
  background-color: white;
}

.add_new_btn_text {
  position: relative;
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
}

.add_new_btn {
  width: 120px;
  border-radius: 8px;
  border: 1px solid #1976d2;
  background-color: #dfeffd;
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 17px 5px 10px;
  gap: 2px;
  color: #1976d2;
}

.search_field {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  padding: 8px 8px 8px 12px;
}

.search_parent {
  font-size: 14px;
  color: #8f9fa6;
}

.icon_search {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.add_new_parent {
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
}

.card_list_parent {
  background-color: #f5f7f8;
  overflow-y: auto;
  text-align: left;
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.search_field input[type='text'] {
  border: none !important;
}

.layout_header_text {
  font-weight: bold;
  line-height: 26px;
}

.layout_header_btn_back {
  color: #b0bec5;
  object-fit: contain;
}

.layout_header_btn_folder {
  position: absolute;
  top: calc(50% - 20px);
  right: 20px;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.layout_header_btn_folder svg {
  stroke: #2c89e6;
}

.layout_header_parent {
  background-color: #fff;
  border-bottom: 1px solid #dee4e7;
  box-sizing: border-box;
  text-align: left;
  font-size: 20px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

@media (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 800px) {
  .detailView {
    width: 94% !important;
  }
}
