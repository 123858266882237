.frameWrapper,
.MainItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.Icon{
  width: 32px;
}
.Title{
  margin-top: 5px;
  font-size: 14px;
  color: #4a4a4a;
  text-align: center;
  overflow: hidden;
  width: 100%;
}

.broken > img{
  opacity: 0.5;
}
.broken > div{
  font-style: italic;
  color: var(--neutral-40);
}