.folder_type_component {
    padding: 0px 0px 0px 12px;
    font-size: 14px;
    overflow: auto;
  }
  
  .folder_type_component_mobile {
    padding: 0px 0px 0px 7px;
    font-size: 14px;
    overflow: auto;
  }
  
  .folder_type_heading_text {
    font-weight: bold;
    line-height: 22px;
  }
  
  .folder_type_heading {
    border-radius: 4px;
    padding: 12px;
    box-sizing: border-box;
  }
  
  .folder_type_container {
    font-family: 'Adobe Clean', sans-serif;
    border-radius: 4px;
  }
  
  .folder_type_clear_all_container {
    overflow: hidden;
    padding: 4px 0px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #1976d2;
  }
  
  .folder_type_clear_all_text {
    font-weight: bold;
    line-height: 20px;
  }
  