.itemWrapper{
    cursor: pointer;
}
.separatorWrapper{
   
}
.itemContainer{
    border-radius: 8px;
    margin: 5px;
}


.itemContainer:hover{
    background-color: var(--neutral-0);
}

.selected{
    background-color: var(--primary-0);
}
