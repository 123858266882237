.print_dialog_parent {
  text-align: left;
  font-size: 14px;
  color: #455a64;
  font-family: 'Adobe Clean', sans-serif;
}

.doc_template_heading {
  font-size: 12px;
  line-height: 16px;
}

.icon_document_parent {
  border-radius: 8px;
  background-color: #f5f7f8;
  overflow: hidden;
}

.document_radio_text {
  line-height: 18px;
}

.radio {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #b0bec5;
  box-sizing: border-box;
}

.radio_selected {
  border-radius: 50%;
  background-color: #fff;
  border: 6px solid #1976d2;
  box-sizing: border-box;
}

.radio_text {
  line-height: 18px;
}

.icon_document_group {
  border-radius: 8px;
  background-color: #f5f7f8;
  border: 1px solid #1976d2;
  box-sizing: border-box;
  overflow: hidden;
}

.icon_document_container {
  border-radius: 8px;
  background-color: #f5f7f8;
  overflow: hidden;
}

.doc_type_heading {
  font-size: 12px;
  line-height: 16px;
}

.doc_lang_heading {
  font-size: 12px;
  line-height: 16px;
}

.footer {
  border-top: 1px solid #dee4e7;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  font-size: 16px;
  color: #1976d2;
}

.footer_btn {
  border-radius: 8px;
  background-color: #dfeffd;
  overflow: hidden;
  padding: 8px 18px;
  box-sizing: border-box;
  cursor: pointer;
}

.btn_label {
  font-weight: bold;
  position: relative;
  line-height: 22px;
}
