.general {
  position: absolute;
  top: 24px;
  left: 24px;
  line-height: 22px;
}


.groupItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  padding: 5px;
}

.groupItemSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  padding: 5px;
  background-color: var(--primary-10);
}

.groupItemSelected:hover {
  cursor: pointer;
}

.groupItem:hover {
  background-color: var(--neutral-10);
  border: 1px solid var(--neutral-20);
  cursor: pointer;
  padding: 4px;
}

.groupItem:active {
  background-color: var(--neutral-20);
  border: 1px solid var(--neutral-30);
  padding: 4px;
  transition: 0s;
}

.generalParent {
  position: relative;
  background-color: var(--neutral-10);
  border-right: 1px solid var(--neutral-20);
  box-sizing: border-box;
  width: 180px;
  height: 378px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--h6-16-size);
  color: var(--neutral-80);
  font-family: var(--h7-14);
}

.appIcon {
  position: relative;
  width: 24px;
  height: 24px;
}

.finder {
  position: relative;
  line-height: 18px;
  text-align: left;
}
.broken > img{
  opacity: 0.5;
}
.broken > div{
  font-style: italic;
  color: var(--neutral-40);
}