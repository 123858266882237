.contact_parent {
  width: 100%;
  height: 215px;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  border-radius: 8px;
  background-color: #f5f7f8;
  border: 1px solid #f5f7f8;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
  margin-bottom: 5px;
}

.contact_name {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  position: absolute;
  top: 20px;
  left: 72px;
}

.description_parent {
  flex-direction: column;
  position: absolute;
  /* top: 118px; */
  left: 16px;
  width: 93%;
  height: 72px;
  align-items: start;
  justify-content: start;
  color: #455a64;
}

.contact_avatar_parent {
  position: absolute;
  top: 18px;
  left: 16px;
  width: 40px;
  height: 40px;
}

.contact_avatar {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  object-fit: cover;
}

.icon_parent {
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 8px;
}

.icon_more {
  font-size: 14px;
  position: absolute;
  top: 21px;
  right: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  color: #b0bec5;
}

.icon_more:hover {
  cursor: pointer;
  color: #1976d2;
}

.icon_email {
  font-size: 14px;
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  color: #b0bec5;
}

.icon_telephone {
  font-size: 14px;
  width: 16px;
  position: relative;
  height: 17px;
  overflow: hidden;
  flex-shrink: 0;
  color: #b0bec5;
}

.icon_mobile {
  font-size: 14px;
  width: 16px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  color: #b0bec5;
}

.contact_details_parent {
  position: absolute;
  top: 44px;
  left: 72px;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 6px;
  color: #5b6c74;
}

.label {
  position: relative;
  line-height: 18px;
}

.dialog_header {
  width: 100%;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  font-family: 'Adobe Clean', sans-serif;
  font-weight: bold;
  color: #36474f;
  text-align: center;
}

.heading_text {
  line-height: 22px;
  font-size: 16px;
  color: #36474f;
  font-weight: bold;
}
.icon_add_parent {
  margin-left: auto;
  font-size: 14px;
  line-height: 12px;
}

.icon_add_text {
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: inline-flex;
  color: #1976d2;
}

.btn_cancel {
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}

.btn_cancel:hover {
  color: #595959 !important;
}

.btn_delete {
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  color: #bc1306;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  border: none;
}
.btn_delete:hover {
  color: #bc1306;
  background-color: #fee8e6;
  text-decoration: underline;
}
.btn_confirm {
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  color: #1976d2;
  white-space: nowrap;
}
/*Specturm Table CSS*/
._5uzlQq_spectrum-Table-headWrapper {
  border-radius: 8px;
}
/*END*/

/*DELETE POP UP CSS*/
.delete_pop_up_header {
  font-weight: bold;
  position: absolute;
  top: 128px;
  left: calc(50% - 49px);
  font-size: 20px;
  line-height: 26px;
}
.delete_pop_up_line {
  position: absolute;
  top: 178px;
  left: calc(50% - 114px);
  background-color: #dee4e7;
  width: 229px;
  height: 1px;
}
.delete_pop_up_warning_text {
  font-size: 16px;
  position: absolute;
  top: 193px;
  left: calc(50% - 146px);
  line-height: 20px;
  color: #455a64;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 293px;
}
.delete_pop_up_cancel_text {
  position: relative;
  line-height: 22px;
  font-weight: bold;
}
.button_cancel {
  width: 155px;
  border-radius: 8px;
  background-color: #dfeffd;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}
.icon_trash {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.delete_pop_up_confirm_text {
  position: relative;
  font-weight: 600;
  color: #bc1306;
  white-space: nowrap;
}
.delete_pop_up_confirm_text:hover {
  color: #bc1306;
}
.button_confirm {
  border-radius: 8px;
  background-color: #fee8e6;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #bc1306;
}
.buttons_parent {
  position: absolute;
  top: 255px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #1976d2;
}

.ellipse_div {
  width: 20%;
  border-radius: 50%;
  background-color: #fee8e6;
  height: 72px;
  margin: auto;
  margin-top: 30px;
  position: static;
}
.delete_alert_icon {
  position: relative;
  top: 18px;
  margin: auto;
  width: 72px;
  height: 72px;
}
.delete_pop_up_parent {
  width: 100%;
  position: absolute;
  border-radius: 8px;
  background-color: #fffefe;
  box-sizing: border-box;
  height: 319px;
  text-align: center;
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.trash_icon path {
  stroke: #bc1306;
}

.add_contact_heading {
  position: absolute;
  top: 24px;
  left: 40px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  width: 253px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.icon_cancel {
  background-color: #fee8e6;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.icon_add {
  background-color: #dfeffd;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.btn_cancel_desc {
  width: 34px;
  border-radius: 50px;
  border: 1px solid #fee8e6;
  box-sizing: border-box;
  height: 34px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fee8e6;
  color: #bc1306;
}

.btn_save_desc {
  width: 34px;
  border-radius: 50px;
  border: 1px solid #dfeffd;
  box-sizing: border-box;
  height: 34px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #dfeffd;
  color: #1976d2;
}

.activity_btn_child {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.activity_btn_master {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
}

.activity_btn_container {
  position: absolute;
  top: -15px;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 18px;
  text-align: center;
  font-size: 14px;
  color: #1976d2;
  font-family: 'Adobe Clean';
}
