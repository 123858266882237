.groupTitle {
    position: relative;
    top: 40px;
    left: 16px;
    font-size: 14px;
    font-weight: 700;
}
.groupContainer {
    position: relative;
    top: 40px;
    width: 100%;
    padding: 10px;
    
}
.groupSeparator {
    position: relative;
    top: 40px;
    width: 100%;
    height: 2px;
    background-color: #ECEFF1;
    margin-bottom: 20px;
}