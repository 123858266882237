@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-react-kanban/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-react-calendars/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';

.custom-dialog .e-dialog .e-dlg-header {
  width: 100% !important;
}

.custom-dialog .e-dialog .e-dlg-header-content {
  padding: 10px !important;
  /* background-color: #e1edff; */
}
/* .custom-dialog .e-dialog .e-footer-content {
  background-color: #e1edff; 
}*/
.custom-dialog .e-dialog .e-dlg-header-content .status {
  float: right;
  /* background-color: cadetblue; */
  width: 80px;
  text-align: center;
  height: 25px;
}

.custom-dialog .float-left {
  float: left;
}

/* AUTO COMPLETE CSS */

.e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-item.e-item-focus {
  /* background-color: #bdd7ff; */
  color: #000;
  font-family: 'Verdana';
  font-size: 12px;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover,
.e-dropdownbase .e-list-item.e-hover {
  /* background-color: #e1edff; */
  color: black;
}
/*Spectrum Control Validation Icon position fix*/
.yxBNXG_spectrum-Icon {
  padding: 0;
}
