.refreshButton
{
    font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  color: var(--neutral-80);
  border: solid 1px var(--neutral-30);
  background-color: var(--neutral-0);
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
}