.appContainer{
    border-top: solid 2px var(--neutral-0);
}

.overlay{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.treeHolder{
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
    width: fill-available;
}


.contextHeader{
    padding-right: 10px;
}
.searchHeader{
    padding-right: 10px;
    padding-left: 10px;
}
.contextText{
    font-size: 20px;
    font-weight: bold;
    padding-left: 24px;
}
.actionButton{
    color: var(--primary-50);
    font-size: 14px;
    font-weight: bold;
} 

.filterIcon > svg > path{
    fill: var(--neutral-30);
}

.detailsHolder{
    background-color: var(--neutral-0);
}

.addNewButton{
    color: var(--primary-50);
    border-color: var(--primary-50);
    border-radius: 5px;
}

.addNewButton:hover{
    background-color: var(--primary-50);
    color: var(--neutral-0);
}