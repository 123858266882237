.menu_heading_text {
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
}

.views_menu_heading {
  padding: 4px 8px;
}

.filter_views_text {
  line-height: 18px;
}

.filter_views_container {
  border-radius: 4px;
  padding: 8px 12px;
  box-sizing: border-box;
}

.filter_views_container:hover {
  background-color: #f5f6f9;
  align-self: stretch;
  border-radius: 8px;
  padding: 8px 12px;
  box-sizing: border-box;
  text-decoration: none;
}

.filter_views_container span {
  color: #455a64 !important;
}

.filter_views_container_selected {
  align-self: stretch;
  font-weight: bold;
  border-radius: 8px;
  background-color: #dfeffd;
  padding: 8px 12px;
  box-sizing: border-box;
  color: #1976d2;
}

.filter_views_parent {
  font-size: 14px;
  color: #455a64;
}

.filter_views_parent > a {
  width: 100%;
  cursor: pointer;
}

.views_menu_container {
  padding: 0px 16px;
  box-sizing: border-box;
}

.separator {
  border-top: 1px solid #eceff1;
  box-sizing: border-box;
}

.filters_menu_wrapper {
  padding: 4px 12px;
  font-size: 12px;
  color: #8f9fa6;
}

.filters_menu_heading_text {
  font-weight: bold;
  line-height: 22px;
}

.filters_filter_heading {
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
}

.filters_menu_parent {
  padding: 0px 0px 0px 20px;
  font-size: 14px;
}

.filters_filter_container {
  border-radius: 4px;
}

.filters_filter_parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.filters_menu_container {
  padding: 0px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #455a64;
}

.filter_menu_parent {
  border-top: 1px solid #dee4e7;
  border-right: 1px solid #dee4e7;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #8f9fa6;
  font-family: 'Adobe Clean', sans-serif;
}

.button_group_wrapper {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.btn_style {
  font-weight: bold;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.button_group_parent {
  text-align: center;
  font-size: 16px;
  color: #1976d2;
}

.Q7FggG_spectrum-Menu-item {
  background-color: transparent !important;
  color: #455a64 !important;
}

.popup_bottom_sheet {
  box-shadow: 4px 0px 16px rgba(38, 50, 56, 0.2);
  border-radius: 16px 16px 0px 0px;
  background-color: transparent;
  overflow-y: auto;
  text-align: center;
  font-size: 16px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.mobile_header_text_1 {
  font-weight: bold;
  line-height: 24px;
}

.mobile_header_text_2 {
  font-size: 12px;
  line-height: 16px;
  color: #455a64;
}

.mobile_header_right_filter {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(100% - 40px);
  width: 24px;
  height: 24px;
  overflow: hidden;
  object-fit: cover;
}

.mobile_header_parent {
  background-color: #fff;
  border-bottom: 1px solid #dee4e7;
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  color: #36474f;
  font-family: 'Adobe Clean', sans-serif;
}

.popup_header_footer_btn {
  font-weight: bold;
  line-height: 22px;
}

.popup_header_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.label3 {
  position: absolute;
  top: calc(50% - 12px);
  left: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 264px;
}
.popup_header_body_parent {
  text-align: left;
}
.bottom-sheet-child {
  position: absolute;
  top: 40px;
  left: 16px;
  width: 164px;
  height: 32px;
  overflow: hidden;
}
.bottom-sheet-item {
  position: absolute;
  top: 40px;
  left: 180px;
  width: 164px;
  height: 32px;
  overflow: hidden;
}
.popup_header_footer {
  border-radius: 8px;
  background-color: #1976d2;
  overflow: hidden;
  padding: 8px 22px;
  box-sizing: border-box;
}
.popup_header_footer_parent {
  border-top: 1px solid #dee4e7;
  box-sizing: border-box;
  color: #fff;
}
.popup_header_top_line {
  border-radius: 24px;
  background-color: #cfd8dc;
}

.headet_text {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 20px);
  line-height: 24px;
}
.popup_header_clearall_text {
  font-weight: bold;
  line-height: 20px;
}

.popup_header_clearall {
  overflow: hidden;
  padding: 4px 0px;
  box-sizing: border-box;
  font-size: 14px;
  color: #1976d2;
}

.popup_header_text_parent {
  position: absolute;
  top: 32px;
  left: 16px;
  width: 328px;
  height: 32px;
  overflow: hidden;
}

.popup_header_text {
  font-weight: bold;
  line-height: 35px;
  text-align: center;
}

.popup_header_parent {
  font-size: 18px;
}
