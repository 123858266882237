.email_subject_read {
  line-height: 20px;
}

.email_subject_unread {
  font-weight: bold;
  line-height: 20px;
}

.email_date {
  font-size: 12px;
  line-height: 16px;
  color: #728289;
  display: inline-block;
}

.user_detail_parent {
  font-size: 16px;
}

.user_name_read {
  line-height: 22px;
}

.user_name_unread {
  position: relative;
  line-height: 22px;
  font-weight: bold;
}

.user_email {
  line-height: 20px;
  color: #728289;
}

.email_type_img {
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 40px;
  width: 26px;
  height: 26px;
  overflow: hidden;
}

.user_detail_parent_line {
  background-color: #dee4e7;
}
