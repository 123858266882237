.container{
    position: relative;
    border-radius: 16px;
    background-color: white;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    overflow: hidden;
}
.container::before{
    content: '';
    display: block;
    position: absolute;
    top: -36px;
    left: 16px;
    right: 16px;
    height: 40px;
    background-color: var(--status-color);
    border-radius: 20px;
    z-index: 2;
}