.title{
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: var(--neutral-90);
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    width: min-content;
}

.line1{
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-60);
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
}

.line2{
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-60);
}

.status{
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-70);
}

.count{
    position: relative;
    top: -2px;
    padding: 0px 6px 2px 6px;
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-80);
    border-radius: 50%;
    background-color: var(--neutral-10);
}

.statusIndicator{
    position: relative;
    top: -5px;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--neutral-70);
}

.search{
    display: none;
}
.folderWrapper {
    padding-left: 0px;
    min-height: 78px;
    padding-top: 6px;
}
.folderWrapper:hover .search {
    display: flex;
}

.statusArea {
    width: 97px;
}
.statusText {
    width: 40px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
}